export default ` 
contact{
    desc
    adres
    email
    phone
    code
    pnSat
    sun
    headForm
    headData
}
`

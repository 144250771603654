import React from 'react'
import styled from 'styled-components'
import Header from '../../components/header/header'

import imgLocation from '../../images/contact/location.svg'
import imgCity from '../../images/contact/city.svg'
import imgEmail from '../../images/contact/email.svg'
import imgClock from '../../images/contact/clock.svg'
import imgPhone from '../../images/contact/phone.svg'

import ContentWrap from '../../components/contentWrap/contentWrap'
import InfoContact from '../../components/infoContact/infoContact'

const StyledFooter = styled.footer`
  width: 100%;
  margin-top: 60px;
  display: none;
  padding: 20px 0 5px;
  background-color: ${({ theme }) => theme.colorBlack};
  @media (min-width: 768px) {
    display: block;
  }
`
const StyledContentWrap = styled(ContentWrap)`
  /* justify-content: center;
  justify-items: center; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledHeader = styled(Header)`
  display: inline-block;
  margin: 0 auto;
`

const List = styled.ul`
  /* 
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px; */
  max-width: 500px;

  margin: 20px auto;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-items: flex-start;
  grid-column-gap: 20px;
  align-items: center;
`

const Footer = props => {
  const data = [
    { img: imgLocation, desc: props.data.adres },
    { img: imgCity, desc: props.data.code },
    { img: imgEmail, desc: props.data.email },
    { img: imgPhone, desc: props.data.phone },
    { img: imgClock, desc: props.data.pnSat },
    { img: imgClock, desc: props.data.sun },
  ]

  return (
    <StyledFooter>
      {/* <Wrap> */}
      <StyledContentWrap>
        <StyledHeader align="center" yellowRect color="colorWhite">
          ośrodek szkolenia kierowców szóstka
        </StyledHeader>
        <List>
          {data.map(el => (
            <InfoContact lowMargin color="colorWhite" img={el.img}>
              {el.desc}
            </InfoContact>
          ))}
        </List>
        {/* </Wrap> */}
      </StyledContentWrap>
    </StyledFooter>
  )
}

export default Footer
